<template>
  <vx-card title="Beginning Balance">
    <vs-tabs>
      <vs-tab label="Debit Note Claims">
        <div class="tab-text">
          <DebitNoteClaim></DebitNoteClaim>
        </div>
      </vs-tab>
      <vs-tab label="Moving Average Price">
        <div class="tab-text">
          <Map></Map>
        </div>
      </vs-tab>
      <vs-tab label="Item Stock">
        <div class="tab-text">
          <ItemStock></ItemStock>
        </div>
      </vs-tab>
      <vs-tab label="AP">
        <div class="tab-text">
          <Ap></Ap>
        </div>
      </vs-tab>
      <vs-tab label="AR Invoice">
        <ar-invoice />
      </vs-tab>
      <vs-tab label="Credit Note"> <credit-note /></vs-tab>
      <vs-tab label="Customer Deposit"> <customer-deposit /> </vs-tab>
      <vs-tab label="Debit Note Return">
        <DebitNoteReturn /> 
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import DebitNoteClaim from "./debit-note-claims/index.vue";
import Map from "./moving-average-price/index.vue";
import ItemStock from "./item-stock/index.vue";
import Ap from "./ap/index.vue";
import ARInvoice from "./ar_invoice/index.vue";
import CustomerDeposit from "./customer_deposit/index.vue";
import CreditNote from "./credit_note/index.vue";
import DebitNoteReturn from "./debit-note-return/index.vue";

export default {
  components: {
    DebitNoteClaim,
    Map,
    ItemStock,
    Ap,
    "ar-invoice": ARInvoice,
    "customer-deposit": CustomerDeposit,
    "credit-note": CreditNote,
    DebitNoteReturn,
  },
};
</script>
